<template>
  <section class="products">
    <v-img
      v-if="banner"
      :src="banner"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div"  id="ctk_top" style="padding-top:10px !important;">
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
      </div>
      <v-layout wrap>
        <v-flex
          v-for="(item, i) in category"
          :key="i"
          xs12
          pa-2
          class="md3 lg3 xl3 ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a :href="/products/ + item.id">
              <v-img
                v-if="item.img"
                :src="item.img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>{{item.name}}</h1>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    category: null,
    catId: null,
    pageId: 2,
    banner: null
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.catId = this.$route.params.pid
    this.getCategory()
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.banner = val.banner[this.pageId][0].img
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCategory () {
      this.https.get('product_categorys').then(response => {
        if (response.code === 0) {
          this.breadcrumbs = [
            { text: '首页', disabled: false, href: '/' },
            { text: '产品系统', disabled: true }
          ]
          this.category = response.data
        }
      })
    }
  }
}
</script>
<style>

</style>
